.plans__grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0px 5px 2px #0000002e;
  box-shadow: 0px 5px 2px #0000002e;
}

.plans__grid .featured-p-padding {
  width: 300px;
}

@media screen and (max-width: 768px) {
  .plans__grid .featured-p-padding {
    overflow-wrap: revert;
  }
}

.plans__grid a {
  color: black;
}

@media screen and (max-width: 768px) {
  .plans__grid {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    overflow: auto;
  }
}
