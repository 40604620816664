.rcards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .rcards {
    overflow: auto;
    white-space: nowrap;
    margin: 14px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
