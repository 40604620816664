.exhibitor__testimonal {
  position: relative;
}

.custom__slider {
  width: 75%;
  margin: 0 auto;
  height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .custom__slider {
    height: 70vh;
    overflow: scroll;
    width: 90%;
  }
}

.custom__slide {
  position: relative;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .custom__slide {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 0px 3px 8px #00000042;
            box-shadow: 0px 3px 8px #00000042;
    overflow: scroll;
    min-width: 90%;
    height: 90%;
    margin: 0.5rem 0.5rem;
    border-radius: 4px;
  }
}

.custom__button {
  border-radius: 50%;
  display: inline-block;
  padding: 0.6rem;
  padding-top: 0.8rem;
  vertical-align: center;
  background-color: white;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  outline: 0;
  border: 0;
  cursor: pointer;
  top: 50%;
}

@media screen and (max-width: 768px) {
  .custom__button {
    display: none;
  }
}

.custom__left-arrow {
  position: absolute;
  left: 6%;
}

.custom__right-arrow {
  position: absolute;
  right: 6%;
}

.event__testimonial-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  box-sizing: border-box;
  width: 40%;
  padding-right: 3rem;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .event__testimonial-content {
    width: 100%;
    padding: 0;
    margin: 1rem 1rem 0;
  }
}

.event__testimonial-content .text {
  font: normal normal normal 16px/28px Futura LT;
}

@media screen and (max-width: 768px) {
  .event__testimonial-content .text {
    margin-top: 0px;
  }
}

.event__testimonial-content .author {
  font: normal normal normal 20px/28px Futura LT;
  letter-spacing: 1px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .event__testimonial-content .author {
    font: normal normal normal 18px/25px Futura LT;
    letter-spacing: 0.9px;
    margin: 0 0 0.2rem 0;
  }
}

.event__testimonial-content .status {
  font: normal normal normal 12px/17px Futura LT;
  letter-spacing: 0.6px;
  color: #918d8d;
  margin: 0 0 0 4rem;
}

@media screen and (max-width: 768px) {
  .event__testimonial-content .status {
    font: normal normal normal 11px/15px Futura LT;
    letter-spacing: 0.55px;
  }
}

.event__testimonial-content .item-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 10px;
}

.exhibitor__testimonal-img {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: -5%;
  z-index: -1;
  width: 70%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .exhibitor__testimonal-img {
    display: none;
  }
}

.test_slides {
  width: 22rem;
  -webkit-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
  height: 100%;
  margin: 0 0 0 1rem;
  overflow: visible;
}
