a {
  text-decoration: none;
}

.blogcard {
  width: 350px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .blogcard {
    width: 100%;
    height: auto;
  }
}

.blogcard img {
  width: 90%;
}

@media screen and (max-width: 768px) {
  .blogcard {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
  }
}
