.galbody {
  padding-top: 50px;
  display: flex;
  max-width: 1300px;
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    padding-top: 0;
    flex-direction: column;
  }
}

.showmorebutton {
  background: #615e5e 0% 0% no-repeat padding-box;
  text-decoration: none;
  outline: none;
  border: none;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding: 1rem 8rem;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    padding: 1rem 5rem;
  }
}


.showconatainer {
  display: flex;
  width: 100%;
  margin: 5% auto;
  padding-top: 5%;
  padding-bottom: 12%;
  /* border-bottom: 5px solid #f5f5f5; */
  justify-content: center;
  align-items: center;

}

.headercontainer {
  background: linear-gradient(
180deg
, #00000000 0%, #000000ed 100%), url(https://weddingasia.s3.ap-south-1.amazonaws.com/weddingasia_static_images/galleries/galleries/Gallery+Cover.jpg);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  .hddetail {
    width: 100%;
    .galleryheader{
      font-size: 2.5rem;
      color: #fff;
      letter-spacing: 4px;
      font-family: Nunito, sans-serif;

        font-weight: 100;

    }
}

}
