.contactbody {
  margin: 0 auto;
  width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .centerbody {
    margin-top: 120px;

  }

  @media screen and (max-width: 768px) {
    width: 100%;
    .centerbody {
      margin-top: 0px;

    }
  }
}

.loadingbody {
  margin: 0 auto;
  text-align: center;
}

.loading {
  width: 200px;

}

.formcontainer {
  position: relative;
  width: 40%;
  margin: 10px auto 0 auto;
}

.formcontainer input {
  padding: 0.5rem 1rem !important;
}

.fromcredentials {
  padding-top: 50px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  ::placeholder {
    padding-left: 12px;
  }

}

input, textarea {
  border: 1px solid #aaa;
  padding: 0;
}

.halfleft {
  width: 50%;
  float: left;
  margin-bottom: 20px;
  padding: 0;
}

.halfright {
  width: 50%;
  float: left;
  margin-bottom: 20px;
  padding: 0;
}

.halfcommon {
  width: 90%;
  height: 35px;
 // margin-left: 4%;
  font-size: 12px;
}

.full {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 35px;
  margin-bottom: 20px;
  font-size: 12px;
}

.fulltextarea {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 150px;
  padding: 15px 0 0 20px;
  font-size: 12px;
}

.submitbtn {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 20px;
  height: 40px;
  border: 1px solid orange;
  color: #fff;
  background-color: #5c5c5c;
  font-size: 12px;
  letter-spacing: 1.2px;
}

@media screen and (max-width: 1024px) {
  .form-container {
    width: 55%;
  }
}

@media screen and (max-width: 780px) {
  .contact-header {
    margin-top: 100px !important;
  }
  .form-container {
    width: 65%;
  }
  .contact-container {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 560px) {
  .contact-container {
    width: 100%;

    margin: 70px auto 100px auto;
  }
  .form-container {
    width: 100%;
  }
}