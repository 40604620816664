.mixblogtop{
  padding-top: 170px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  text-align: center;
  @media screen and (max-width: 768px){
    padding-top: 0;
  }
}