.procedure .procedure__further:last-child {
  display: none;
}

.procedure h1 {
  text-align: center;
  text-transform: uppercase;
}

.procedure .procedure__head {
  letter-spacing: 3px;
  font-weight: 500;
}

@media screen and (max-width: 426px) {
  .procedure .procedure__head {
    font-size: 24px;
  }
}

.procedure .procedure__para {
  text-align: center;
  max-width: 50ch;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

@media screen and (max-width: 426px) {
  .procedure .procedure__para {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.procedure .procedure__container {
  max-width: 882px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 768px) {
  .procedure .procedure__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 769px) {
  .procedure .procedure__container section:last-child {
    margin-right: 3rem;
  }
}

.procedure .procedure__container section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .procedure .procedure__container section {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
}

.procedure .procedure__container .procedure__box {
  width: 80px;
  height: 80px;
  border: 1px solid #eb934b;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 1.5rem;
}

@media screen and (max-width: 426px) {
  .procedure .procedure__container .procedure__box {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 320px) {
  .procedure .procedure__container .procedure__box {
    width: 50px;
    height: 50px;
  }
}

.procedure .procedure__container .procedure__box > p {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  font-size: 1.5rem;
}

.procedure .procedure__container .procedure__instr {
  margin-top: 2.5rem;
}

.procedure .procedure__container .procedure__instr > h1 {
  font-size: 16px;
  color: #eb934b;
  font-weight: 500;
}

.procedure .procedure__container .procedure__instr > p {
  font-size: 12px;
  margin-left: -2rem;
  margin-right: -2rem;
}

@media screen and (max-width: 768px) {
  .procedure .procedure__container .procedure__instr > p {
    width: 30ch;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

.procedure__further {
  margin: 1.5rem 1rem 0;
  min-width: 150px;
}

@media screen and (max-width: 768px) {
  .procedure__further {
    position: relative;
    left: -19%;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    min-height: 150px;
    overflow: hidden;
    margin: 0.5rem 1rem 0;
  }
}

@media screen and (max-width: 426px) {
  .procedure__further {
    left: -21%;
  }
}

@media screen and (max-width: 321px) {
  .procedure__further {
    left: -23%;
  }
}

.procedure__further p {
  text-align: center;
  font-size: 12px;
}

.procedure__line {
  border-style: none none dotted;
  width: 100%;
  color: white;
  background-color: white;
  border-top: 3px dashed #eb934b;
}

@media screen and (max-width: 768px) {
  .procedure__line {
    width: 150px;
  }
}
