.mainbody{
  //padding-top: 140px;
  padding: 160px 60px 60px 60px;
  max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  a{
    color: black;
  }
  @media screen and (max-width: 768px){
    width: 90%;
    padding: 12px
  }
}