// typography
.head--1 {
  font: normal normal normal 24px/38px Nunito;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  @media screen and (min-width: 768px) {
    font: normal normal normal 40px/64px Nunito;
    letter-spacing: 2px;
  }
}
.head--2 {
  font: normal normal normal 20px/27px Nunito;
  text-transform: uppercase;
  text-align: center;
  @media screen and (min-width: 768px) {
    font: normal normal normal 32px/45px Nunito;
  }
}

.text--primary {
  font: normal normal normal 16px/26px Nunito;
  @media screen and (min-width: 768px) {
    font: normal normal normal 24px/38px Nunito;
  }
}
.text--secondary {
  font: normal normal normal 16px/28px Nunito;
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
}
.text--tertiary {
  //   text-align: center;
  font: normal normal normal 14px/26px Nunito;
  color: #5c5c5c;
}
.text--accent {
  text-align: center;
  font: normal normal normal 10px/25px Nunito;
  letter-spacing: 1px;
  color: #a19b9b;
  text-transform: uppercase;
  @media screen and (min-width: 768px) {
    font: normal normal normal 12px/16px Nunito;
    letter-spacing: 1.2px;
    color: #000000;
    text-align: center;
    margin-left: 1rem;
  }
}
//grid
.grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: 1fr;
  @media screen and (min-width: 769px) {
    grid-template-columns: 45fr 55fr;
  }
}

// button
.btn {
  font: normal normal normal 12px/16px Nunito;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  background: #5c5c5c;
  width: 100%;
  padding: 9px 10px;
  border: 0;
  cursor: pointer;
  text-align: center;
  @media screen and (min-width: 769px) {
    width: fit-content;
    font: normal normal normal 22px/30px Nunito;
    letter-spacing: 2.2px;
    padding: 0.8rem 1rem;
  }
}

.event {
  color: black;
  letter-spacing: 0px;
}

// hero
#hxhbity{
  height: 70vh;
}
.event__hero {
  margin-top: 120px;
  position: relative;
  color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-origin: border-box;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // padding-left: 8vmin;
  padding-top: 1px;
  @media screen and (max-width: 769px) {
    margin-top: 0;
    height: 65vh;
  }
  //  height: 100%;
  //  margin-top: 120px;
  //}
  address {
    font-size: 12px;
    text-transform: capitalize;
    font-style: normal;
    margin: 1.5rem 0 4.5rem;
    @media screen and (min-width: 769px) {
      font: normal normal normal 14px/29px Nunito;
      margin: 0;
    }
  }
}
.event__hero-container {
  margin-top: 9rem;
  margin-left: 2.5rem;
  @media screen and (min-width: 769px) {
    width: 80%;
    margin-top: 3rem;
  }
}
.event__hero-head {
  width: 90%;
  @media screen and (max-width: 769px) {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
.heroIntroi {
  color: #fff;
  text-transform: uppercase;
  line-height: 30px;
  font-size: 1rem !important;
  width: 360px;
  @media screen and (max-width: 769px) {
    color: #fff;
    text-transform: uppercase;
    line-height: 30px;
    font-size: 12px;
    width: 100%;
  }
  span {
    font-size: 12px;
    @media screen and (min-width: 769px) {
      font: normal normal normal 16px/78px Nunito;
      margin: 0;
    }
  }
}
.frcard{
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
}
.four-box-row {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
.r {
  border: 1px solid#70707080;
  padding: 20px 17px;
  background-color: #fff;
  margin: auto 16px;
  min-width: 200px;
  height: 337.2px;
  flex: 1;
}
.r h2 {
  text-transform: uppercase;
  font-size: 20px;
}
.r p {
  margin: 40px auto;
  font-size: 15px;
  line-height: 25px;
}
.event__hero-time {
  font-size: 14px;
  margin: 1.5rem 0;
  @media screen and (min-width: 768px) {
    font: normal normal normal 20px/78px Nunito;
    margin: 0;
  }
}
.reg-with-para {
  margin: 100px 0 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.reg-with-para p {
  font-size: 10px;
  text-transform: uppercase;
}
.registration-button-class {
  font-size: 20px;
  background-color: #5c5c5c;
  border: 2px solid #5c5c5c;
  color: #fff;
  height: 50px;
  margin-top: 25px;
  margin-bottom: 23px;
  width: 360px;
  @media screen and (max-width: 769px) {
    width: 100%;
  }

}
.registration-anchor:link, .registration-anchor:visited {
  text-decoration: none;
  color: #fff;
}
.btns {
  margin: 2rem 0 0.5rem;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
  .btn {
    // font: normal normal normal 10px/14px Futura LT;
    font-size: 18px;
  }
  .btn:nth-of-type(1) {
    margin-bottom: 2rem;
    width: 60%;
    background-color: #F18D35;
    @media screen and (min-width: 769px) {
      width: 25%;
      margin-right: 3rem;
    }
  }
  .btn:nth-of-type(2) {
    width: 60%;
    height: fit-content;
    background-color: transparent;
    border: 0.5px solid #ffffff;
    @media screen and (min-width: 769px) {
      width: 25%;
    }
  }
}

.event__hero-location {
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  width: 100%;
  @media screen and (max-width: 769px) {
    display: none;
  }
  display: flex;
}
.event__hero-media a{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #918d8d;
  & > div {
    margin-left: 0.7rem;
  }
  &:hover{
    color: #F18D35;
  }
}
.even__hero-share {
  box-sizing: border-box;
  background-color: transparent;
  color: white;
  width: fit-content;
  // font-size: 0.8rem;
  margin: 0 1rem 0.5rem 0;
  height: 100%;
  & > div {
    border: 0.5px solid white;
    padding: 12px;
    display: flex;
    align-items: center;
    & > span {
      margin-left: 5px;
    }
  }
}
.event-scroll{
  // width: 10px;
  height: 65px;
  width: 85px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // margin-top: 14rem;
  margin: 2rem 0 4rem;
  font-size: 1.4rem;
  transform: rotate(
                  90deg
  );
}
.event__hero-locate {
  width: 75%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  background-color: white;
  color: #918d8d;
  font: normal normal normal 12px/19px Nunito;
  // display: flex;
  gap: 2rem;
  padding: 2rem;
  // justify-items: center;
  align-items: center;
  @media screen and (max-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
.media-mobile {
  display: none;
  margin-top: 3rem;
  @media screen and (max-width: 769px) {
    box-sizing: border-box;
    display: grid;
    width: 100%;
    padding: 2rem 2rem 2rem 7rem;
  }
  @media screen and (max-width: 426px) {
    padding: 2rem 2rem 2rem 2rem;
  }
}
// media
.grid-gap {
  @media screen and (min-width: 769px) {
    grid-template-columns: 40fr 57fr;
  }
}
.event__media-container{
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 769px) {
    width: 60%;
    margin: auto;
  }
  @media screen and (max-width: 426px) {
    width: 80%;
    margin: auto;
  }

}
.event__media {
  margin: 4rem 10px;
  @media (min-width: 790px) and (max-width: 1025px) {
    margin-left: 2rem;
  }
  .event__media-img {
    width: 100%;
    // height: 70vh;
    min-height: 350px;
    object-fit: cover;
    @media screen and (min-width: 426px) {
      height: 80vh;
    }
    @media screen and (min-width: 769px) {
      max-width: 385px;
      height: auto;
    }
  }

  .event__media-head {
    width: 15ch;
    text-align: center;
    font: normal normal normal 24px/32px Nunito;
    margin: 2rem auto 0;
    text-transform: uppercase;
    @media screen and (min-width: 769px) {
      font: normal normal normal 32px/45px Nunito;
      margin: 2rem 0 0 -1.5rem;
    }
  }
  .event__media-year {
    // display: none;
    // @media screen and (min-width: 769px) {
    display: block;
    font: normal normal bold 92px/128px Nunito;
    color: #d8d8d86b;
    text-transform: uppercase;
    margin: 2rem 0;
    text-align: center;
    @media screen and (min-width: 769px) {
      text-align: left;
    }
  }
  .event__media-texts {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    @media screen and (min-width: 769px) {
      grid-template-columns: repeat(2, 0.4fr);
      column-gap: 3rem;
    }
    .event__media-text {
      margin: 1.5rem auto;
      max-width: 80ch;
      text-align: center;
      @media screen and (min-width: 769px) {
        text-align: left;
        margin: 0;
      }
    }
  }
}

.previous-event__album {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 50vh);
  grid-auto-rows: 25vh;
  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 65vh);
    grid-auto-rows: 60vh;
    // grid-template-areas:
    //   "image1 image1 image1 image2 image2 image2"
    //   "testimonal1 testimonal1 testimonal1 image3 image3 image3"
    //   "image4 image4  image5 image5 testimonal2  testimonal2 ";
  }
}
.previous__event__images {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.previous__event__images > p {
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 100%;
    text-transform: uppercase;
    position: absolute;
    bottom: 0.5%;
    color: #ffffff;
    text-align: center;
  }
}

.previous-event__img1 {
  @media screen and (min-width: 769px) {
    // grid-area: image1;
    grid-column: auto / span 3;
  }
}
.previous-event__img2 {
  @media screen and (min-width: 769px) {
    // grid-area: image2;
    grid-column: auto / span 3;
  }
}
.previous-event__testimonal1 {
  margin: 0 10px 0;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  .previous__testimonal1-content::before {
    content: url("../../../images/event/open-quote.png");
    margin-right: 10px;
    margin-top: 1rem;
  }
  .previous__testimonal1-content {
    text-align: left;
    width: 80%;
    margin: 0 auto;
  }
  .author {
    font: normal normal normal 24px/33px Nunito;
    letter-spacing: 1.2px;
    margin-top: 0;
    width: 80%;
    margin: 10px auto 0;
  }
  .status {
    font: normal normal normal 12px/17px Nunito;
    letter-spacing: 0.6px;
    width: 80%;
    margin: 10px auto 0;
  }
  @media screen and (min-width: 769px) {
    margin: 0;
    // grid-area: testimonal1;
    grid-column: auto / span 3;
  }
}
.previous-event__img3 {
  @media screen and (min-width: 769px) {
    // grid-area: image3;
    grid-column: auto / span 3;
  }
}
.previous-event__img4 {
  @media screen and (min-width: 769px) {
    // grid-area: image4;
    grid-column: auto / span 2;
  }
}
.previous-event__img5 {
  @media screen and (min-width: 769px) {
    // grid-area: image5;
    grid-column: auto / span 2;
  }
}
.previous-event__testimonal2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .testimonal2__btn {
    width: 90%;
    @media screen and (min-width: 769px) {
      font-size: 18px ;
    }
  }
  @media screen and (min-width: 769px) {
    // grid-area: testimonal2;
    grid-column: auto / span 2;
  }
}

// exhibitor
.exhibitors {
  margin: 0rem 10px 4rem;
  @media screen and (min-width: 769px) {
    margin: 6rem 10px;
  }
}
.exhibitors__left {
  max-width: 450px;
  margin: 0 auto;
  .exhibitors__left-head {
    letter-spacing: 4px;
    @media screen and (min-width: 769px) {
      text-align: left;
    }
  }
}
.exhibitors__right {
  margin-top: 2rem;
  margin-bottom: 2rem;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 3rem;
  justify-items: center;
  align-items: center;
  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.exhibitor__btn {
  margin: 2rem 0 1rem;
}
.exhibitor__move {
  display: none;
  @media screen and (min-width: 769px) {
    display: inline-block;
  }
}
.exhibitor__down {
  // display: none;
  @media screen and (min-width: 769px) {
    display: none;
  }
}
.locationContainer{
  margin: 0 auto;
  text-align: center;
  h1{
    text-align: center;
    margin-bottom: 60px;
    font-size: 20px;
    letter-spacing: 6.4px;
  }
  h2{
    text-align: center;
    margin-bottom: 50px;
    font-size: 19px;
    letter-spacing: 0px;
  }
  p{
    text-align: center;
    margin-bottom: 50px;
    color: orange;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 46px;
  }
}

//testimonal
.event-testimonal {
  margin-bottom: 5rem;
  .legend {
    margin: 4rem 0;
  }
}
.brand-partners{
  text-align: center;
  margin: 160px 0 0 0;
  h1 {
    text-transform: uppercase;
    font-size: 32px;
    font-family: Nunito, sans-serif;
    letter-spacing: 6.4px;
  }
}

.brand-logo-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.four-gallery-row {
  width: 55%;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media screen and(max-width:768px) {
    width: 100%;
  }
}
.brand-partners p {
  width: 30%;
  margin: 50px auto;
  font-size: 14px;
  font-family: Nunito, sans-serif !important;
}
