.des{
  padding-top: 130px;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  h1{
    font-weight: 100;
  }
  @media screen and (max-width: 768px) {
    padding-top: 0px;

  }
}
.featred{
  padding-top: 12px;
  display: flex;
  max-width: 1300px;
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
  flex-wrap: wrap;
  @media screen and (max-width: 768px){
    padding-top: 0;
    flex-direction: column;
  }
}
.galerybody {
  width: 90%;
  margin: 1% auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: repeat(6, 7vw); */
  grid-template-rows: auto;
  grid-gap: 2px;
  padding-top: 26px;
  @media screen and (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    padding-top: 0px;
    .gallery__item {
      flex: 1;
      margin-top: 4%;
      margin-bottom: 2%;
      width: 95%;
      height: 40%;
    }
    }
  figure {
    margin: 0;
  }
  @media screen and (max-width: 550px) {
    .gallery__item {
      margin-top: 2%;
      margin-bottom: 2%;
    }
  }
  @media screen and (max-width: 768px) {
    .gallery__item {
      flex: 1;
      margin-top: 4%;
      margin-bottom: 2%;
      width: 95%;
      height: 40%;
    }
  }
  .galleryitem:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .galleryitem:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .galleryitem:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .galleryitem:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .galleryitem:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  .galleryitem:nth-child(6) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .galleryitem:nth-child(7) {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .galleryitem:nth-child(8) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 6;
  }
  .galleryitem:nth-child(9) {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 7;
  }
  .galleryitem:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 7;
  }
  .galleryitem:nth-child(11) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 7;
  }
  .galleryitem:nth-child(12) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 7;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
  }

}

.css-1om643u{
  display: none;
}