.des {
  padding-top: 130px;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}

.des h1 {
  font-weight: 100;
}

@media screen and (max-width: 768px) {
  .des {
    padding-top: 0px;
  }
}

.featred {
  padding-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1300px;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 0 auto;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .featred {
    padding-top: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.galerybody {
  width: 90%;
  margin: 1% auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[5];
      grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: repeat(6, 7vw); */
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-gap: 2px;
  padding-top: 26px;
}

@media screen and (max-width: 768px) {
  .galerybody {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 0px;
  }
  .galerybody .gallery__item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-top: 4%;
    margin-bottom: 2%;
    width: 95%;
    height: 40%;
  }
}

.galerybody figure {
  margin: 0;
}

@media screen and (max-width: 550px) {
  .galerybody .gallery__item {
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media screen and (max-width: 768px) {
  .galerybody .gallery__item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-top: 4%;
    margin-bottom: 2%;
    width: 95%;
    height: 40%;
  }
}

.galerybody .galleryitem:nth-child(1) {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
      grid-row-start: 1;
  grid-row-end: 3;
}

.galerybody .galleryitem:nth-child(2) {
  -ms-grid-column: 3;
      grid-column-start: 3;
  grid-column-end: 6;
  -ms-grid-row: 1;
      grid-row-start: 1;
  grid-row-end: 4;
}

.galerybody .galleryitem:nth-child(3) {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 2;
  -ms-grid-row: 3;
      grid-row-start: 3;
  grid-row-end: 4;
}

.galerybody .galleryitem:nth-child(4) {
  -ms-grid-column: 2;
      grid-column-start: 2;
  grid-column-end: 3;
  -ms-grid-row: 3;
      grid-row-start: 3;
  grid-row-end: 4;
}

.galerybody .galleryitem:nth-child(5) {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
  -ms-grid-row: 4;
      grid-row-start: 4;
  grid-row-end: 6;
}

.galerybody .galleryitem:nth-child(6) {
  -ms-grid-column: 3;
      grid-column-start: 3;
  grid-column-end: 4;
  -ms-grid-row: 4;
      grid-row-start: 4;
  grid-row-end: 5;
}

.galerybody .galleryitem:nth-child(7) {
  -ms-grid-column: 4;
      grid-column-start: 4;
  grid-column-end: 6;
  -ms-grid-row: 4;
      grid-row-start: 4;
  grid-row-end: 5;
}

.galerybody .galleryitem:nth-child(8) {
  -ms-grid-column: 3;
      grid-column-start: 3;
  grid-column-end: 4;
  -ms-grid-row: 5;
      grid-row-start: 5;
  grid-row-end: 6;
}

.galerybody .galleryitem:nth-child(9) {
  -ms-grid-column: 4;
      grid-column-start: 4;
  grid-column-end: 6;
  -ms-grid-row: 4;
      grid-row-start: 4;
  grid-row-end: 7;
}

.galerybody .galleryitem:nth-child(10) {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 2;
  -ms-grid-row: 6;
      grid-row-start: 6;
  grid-row-end: 7;
}

.galerybody .galleryitem:nth-child(11) {
  -ms-grid-column: 2;
      grid-column-start: 2;
  grid-column-end: 3;
  -ms-grid-row: 6;
      grid-row-start: 6;
  grid-row-end: 7;
}

.galerybody .galleryitem:nth-child(12) {
  -ms-grid-column: 3;
      grid-column-start: 3;
  grid-column-end: 4;
  -ms-grid-row: 6;
      grid-row-start: 6;
  grid-row-end: 7;
}

.galerybody img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: middle;
}

.css-1om643u {
  display: none;
}
