.weddingcard{
    max-width: 630px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Nunito', sans-serif;
    margin-top: 35px;
    a{
        color: black;
    }
    #{&}__img{
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    #{&}__content{
        padding: 1rem;
        margin-bottom: 2rem;
        h3{
            text-transform: uppercase;
            font-weight: 600;
            color: #eb934b;
            font-size: 11px;
        }
        &>p:nth-of-type(1){
            font-size: 22px;
            margin: 1rem 0;
        }     
        &>p:nth-of-type(2){
            width: 90%;    
        }    
    }
}
