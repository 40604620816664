.container{
  padding: 0 10px;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 120px;
}
.head{
  text-transform: uppercase;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}
.contact{
  text-align: center;

  margin: 1rem auto;
  &>a{
    color: #F18D35;
  }
}
.checked{

  margin: 4rem auto 1rem;
  color: #55CC59;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>p{
    text-align: center;
  }
}