.workbody {
  margin-top: 150px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .workbody {
    margin-top: 0;
  }
}

.workbody h3 {
  font: normal normal bold 28px/38px Nunito Sans;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}

.workbody p {
  width: 600px;
  margin: 0 auto;
  font-family: Nunito, sans-serif !important;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .workbody p {
    width: 100%;
    margin: 0 auto;
  }
}

.workbody .wroktitle {
  font: normal normal 600 18px/24px Nunito Sans;
  letter-spacing: 0px;
  color: #2A2A2A;
  opacity: 1;
}

.workbody .wroklist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px gray solid;
  width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.workbody .wroklist a {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .workbody .wroklist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px gray solid;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: left;
  }
}

.workbody .wroklistcolo a {
  color: #FF840D;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  color: #FF840D;
  opacity: 1;
}
