.slick-slider ul li button::before {
  color: black;
  font-size: 12px;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .slick-slider ul li button::before {
    font-size: 8px;
  }
}

.slick-slider li.slick-active button::before {
  color: white;
}

.slick-slider .slider__img {
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-position: center;
     object-position: center;
}

.slick-dots {
  bottom: 4%;
  right: 4%;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .slick-dots {
    text-align: left;
    left: 4%;
    width: 90%;
  }
}

.slider-container {
  text-transform: uppercase;
  font-family: Nunito, sans-serif;
  color: white;
  position: relative;
  width: 100%;
  height: 104vh;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#00000000), to(#000000ED)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000ED 100%) 0% 0% no-repeat padding-box;
}

@media screen and (max-width: 768px) {
  .slider-container {
    height: 90vh;
  }
}

.slider-container .slider-content {
  margin-left: 5rem;
  margin-bottom: 5rem;
}

.slider-container .slider-content > p {
  margin: 3rem 0;
}

.slider-container .slider-content > h2 {
  margin: 3rem 0;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .slider-container .slider-content {
    margin-left: 1rem;
    margin-bottom: 5rem;
  }
}

.slider-container .slider-btn {
  font: normal normal normal 12px/16px Nunito;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  background: transparent;
  padding: 9px 10px;
  border: 1px solid white;
  cursor: pointer;
}

.slider-container .slider-btn a {
  opacity: 0.8;
  color: #ffffff;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .slider-container .slider-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font: normal normal normal 20px/25px Nunito;
    padding: 0.4rem 0.2rem;
  }
}

.slick-next {
  display: none !important;
}

.slick-prev {
  display: none !important;
}
