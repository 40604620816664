.img__grid{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-template-rows: repeat(4, 22vh);
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(3, 35vh);
    }
    h1,h2,h3{
        text-transform: uppercase;
        font-weight: 500;
    }
    h1,h3{
        @media screen and (max-width: 768px){
            font-size: 12px;
            margin-bottom: 1rem;
        }
    }
    .gridimg-1{grid-column: 1/span 4;grid-row: 1/span 4;
        position: relative;
        padding-left: 5vmin;
        p{
            margin: 2rem 0 3rem;
            max-width: 70ch;
            @media screen and (max-width: 768px){
                font-size: 8px;
                margin: 0rem 0 1.5rem;
            }

        }
        @media screen and (max-width: 768px){
            grid-column: 1/span 2;grid-row: auto/span 1;
        }
    }
    .gridimg-2{grid-column: 5/span 2 ;grid-row: 1/span 2;
        position: relative;
        align-items: center;
        justify-content: flex-end !important;
        cursor: pointer;
        @media screen and (max-width: 768px){
            grid-column: 1/span 2;grid-row: auto/span 1;
        }}
    .gridimg-3{grid-column: 5/span 1; grid-row: 3 / span 2;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 768px){
            grid-column: 1/span 1;grid-row: auto/span 1;
        }}
    .gridimg-4{grid-column: 6/ span 1; grid-row: 3 / span 2;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 768px){
            grid-column: 2/span 1;grid-row: auto/span 1;
        }}
    .gridimg{background-repeat: no-repeat !important;background-position: center !important;background-size: cover !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
    }
    .expand__image{
        text-transform: uppercase;
        @media screen and (max-width: 768px){
            font-size: 11px;
        }
    }
}
.img-grid__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    cursor: pointer;
    width: 30px !important;
    cursor: pointer;
    @media only screen and (max-width: 769px) {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .img-grid__playimg{
    width: 100%;
    height: 100% !important;
  }
