.skeleton {
    background: #615e5e;
    /* margin: 10px 0; */
    
   
  }
  
  .skeleton.text {
    margin: 0 auto;
    width: 80%;
    height: 300px;
  }
  
  .skeleton.title {
    width: 50%;
    height: 30px;
    margin-bottom: 15px;
  }

  .skeleton.hero{
      width: 90%;
      height: 90vh;
     margin: 48px;
      position: relative;
  }

  .skeleton.eventcard{
    width: 390px;
    height: 520px;
  }

  .skeleton.eventfilter{
    width: 140px;
    height: 19px;
  }
  .skeleton.eventInsideCover{
    width: 100%;
    height: 100vh;
  }
  