.introduce{
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 0 40px 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .textarea{
    width: 48%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .heading{
    font-size: 4rem;
    line-height: 4.5rem;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    color: #C7C7C7;
    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  .desc{
    width: 75%;
    font-size: 16px;
    line-height: 30px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .button{
    background-color: #5C5C5C;
    width: 80%;
    padding: 15px 0;
    color: #fff;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3.2px;
    line-height: 21px;
    text-transform: uppercase;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    a{
      color: white;
    }


  }
  .mobView{
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

}
#right{
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}