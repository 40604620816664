.topmrgin{
  padding-top: 140px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;

  @media screen and(max-width: 768px){
    padding-top: 0;
  }
}
.hmdis{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}