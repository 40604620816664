.exhibitor__testimonal {
  position: relative;
}
.custom__slider {
  width: 75%;
  margin: 0 auto;
  height: 70vh;
  display: flex;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: 70vh;
    overflow: scroll;
    width: 90%;
  }

}
.custom__slide {
  position: relative;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.5s;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    box-sizing: border-box;
    box-shadow: 0px 3px 8px #00000042;
    overflow: scroll;
    min-width: 90%;
    height: 90%;
    margin: 0.5rem 0.5rem;
    border-radius: 4px;
  }
}

.custom__button {
  border-radius: 50%;
  display: inline-block;
  padding: 0.6rem;
  padding-top: 0.8rem;
  vertical-align: center;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  outline: 0;
  border: 0;
  cursor: pointer;
  top: 50%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.custom__left-arrow {
  position: absolute;
  left: 6%;
}

.custom__right-arrow {
  position: absolute;
  right: 6%;
}

// event__testimonial-content
.event__testimonial-content {
  box-sizing: border-box;
  box-sizing: border-box;
  width: 40%;
  padding-right: 3rem;
  background-color: white;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin: 1rem 1rem 0;
  }
  .text {
    font: normal normal normal 16px/28px Futura LT;
    @media screen and (max-width: 768px) {
      margin-top: 0px;
    }
  }
  .author {
    font: normal normal normal 20px/28px Futura LT;
    letter-spacing: 1px;
    margin: 0;
    @media screen and (max-width: 768px) {
      font: normal normal normal 18px/25px Futura LT;
      letter-spacing: 0.9px;
      margin: 0 0 0.2rem 0;
    }
  }
  .status {
    font: normal normal normal 12px/17px Futura LT;
    letter-spacing: 0.6px;
    color: #918d8d;
    margin: 0 0 0 4rem;
    @media screen and (max-width: 768px) {
      font: normal normal normal 11px/15px Futura LT;
      letter-spacing: 0.55px;
    }
  }
  .item-rating {
    display: flex;
    user-select: none;
    margin-bottom: 10px;
  }
}
.exhibitor__testimonal-img {
  box-sizing: border-box;
  margin-left: -5%;
  z-index: -1;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

// .exhibitor__testimonal-img1 {
//   height: 100%;
//   min-width: 15%;
//   object-fit: cover;
// }
// .exhibitor__testimonal-img2 {
//   height: 80%;
//   min-width: 10%;
//   margin: 0 0 0 1rem;
//   object-fit: contain;
// }
// .begin {
//   order: -1;
// }
.test_slides{
  width: 22rem;
  transition: all 0.5s linear 0s;
  height: 100%;
  margin: 0 0 0 1rem;
  overflow: visible;
}
// .hide{
//   // display: none;
//   height: 0%;
//   overflow: hidden;
//   max-height: 0%;
//   width: 0;
//   transition: all 0.5s;
// }
// .show{
//   height: 100%;
//   overflow: hidden;
//   max-height: 100%;
//   width: 38%;
// }
