.form__btn {
  font: normal normal normal 12px/16px Nunito, sans-serif;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  background: #5c5c5c;
  width: 100%;
  padding: 9px 8px;
  border: 1px solid orange;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    font: normal normal normal 20px/15px Nunito, sans-serif;
    letter-spacing: 2.2px;
    padding: 0.8rem 1rem;
  }
}
.loadingimg{
  margin: 0 auto;
  text-align: center;
}
.upcs {
  text-transform: uppercase;
}
.form__container {
  color: #707070;
}
.form__container > h1 {
  text-align: center;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
.form__container {
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
    .form__heading{
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
      @media screen and (max-width: 768px) {
        margin: 0 2rem;
        width: auto;
      }
    }
    .form__nav{
      display: flex;
      justify-content: space-between;
      margin-top: 1.4rem;
    }
}

.form{
  max-width: 600px;
  margin: 3rem auto;
  @media screen and (max-width: 768px) {
    margin: 2rem 0.5rem;
  }
}
input,textarea{
  box-sizing: border-box;
}
.form__inputs{
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}
.w-100{
  width: 100%;
}
.form__division{
  display: flex;
  justify-content: space-between;
  input{
    width: 0;
    flex: 0.47;
  }
}
.form__address{
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  input{
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}