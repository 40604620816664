.procedure {
  .procedure__further:last-child{
    display: none;
  }

  h1 {
    text-align: center;
    text-transform: uppercase;
  }
  #{&}__head {
    letter-spacing: 3px;
    font-weight: 500;
    @media screen and (max-width: 426px) {
      font-size: 24px;
    }
  }
  #{&}__para {
    text-align: center;
    max-width: 50ch;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    @media screen and (max-width: 426px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  #{&}__container {
    max-width: 882px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    //margin-top: 3rem;


    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 2rem;
    }
    section:first-child {
      @media screen and (min-width: 769px) {
        //margin-left: 3rem;
      }
    }
    section:last-child {
      @media screen and (min-width: 769px) {
        margin-right: 3rem;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
    .procedure__box {
      width: 80px;
      height: 80px;
      border: 1px solid #eb934b;
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;
      @media screen and (max-width: 426px) {
        width: 70px;
        height: 70px;
      }
      @media screen and (max-width: 320px) {
        width: 50px;
        height: 50px;
      }
      & > p {
        transform: rotate(-45deg);
        font-size: 1.5rem;
      }
    }
    .procedure__instr {
      margin-top: 2.5rem;
      & > h1 {
        font-size: 16px;
        color: #eb934b;
        font-weight: 500;
      }
      & > p {
        font-size: 12px;
        margin-left: -2rem;
        margin-right: -2rem;
        @media screen and (max-width: 768px) {
          width: 30ch;
          margin-left: 0rem;
          margin-right: 0rem;
        }
      }
    }
  }
}
.procedure__further {
  margin: 1.5rem 1rem 0;
  min-width: 150px;


  @media screen and (max-width: 768px) {
    position: relative;
    left: -19%;
    transform: rotate(-90deg);
    min-height: 150px;
    overflow: hidden;
    margin: 0.5rem 1rem 0;
  }

  @media screen and (max-width: 426px) {
    left: -21%;
  }
  @media screen and (max-width: 321px) {
    left: -23%;
  }
  p {
    text-align: center;
    font-size: 12px;
  }
}
.procedure__line {
  border-style: none none dotted;
  width: 100%;
  color: white;
  background-color: white;
  border-top: 3px dashed #eb934b;

  @media screen and (max-width: 768px) {
    width: 150px;
  }
}
