.mainbodyfrm {
  padding-top: 130px;
}

@media screen and (max-width: 768px) {
  .mainbodyfrm {
    padding-top: 0px;
  }
}

.mainbodyfrm h1 {
  font-weight: 100;
}

.widthContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1150px;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 1vmin;
}

.widthContainer .active {
  border-bottom: 3px solid orange;
}

.widthContainer ul li {
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.widthContainer ul a {
  text-decoration: none;
  list-style-type: none;
}

.widthContainer .navlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 537px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding-left: 0px;
}
