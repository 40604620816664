 .skeleton{
     background-color: chocolate;
     margin: 10px 0;
     border-radius: 4px;

     .title{
         width: 50%;
         height: 30px;
         margin-bottom: 15px;
     }
 }
 .skeleton.text{
    width: 100%;
    height: 10px;
 }