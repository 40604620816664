.bgmodelbridestory{
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 100;
  .modelconten{
    width: 449px;
    height: 440px;
    background-color: #fff;
    text-align: center;
    position: relative;}
    .modelcontentimage{
      height: 102px;
      width: 102px;
      border-radius: 50%;
      margin-top: 23px;
  }
  .modelcontentname{
    margin-top: 25px;
    font-size: 18px;
    color: #5C5C5C;
    text-align: center;
    margin-bottom: 0;
  }
  .modelcontentbridtobe{
    margin-top: 30px;
    font-size: 12px;
    color: #5C5C5C;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .modelcontentpara {
    font-size: 14px;
    color: #5C5C5C;
    text-align: center;
    width: 80%;
    margin: 35px auto 0 auto;
  }
  .buttons {
    width: 80%;
    margin: 55px auto 0 auto;
  }
  .fillbtn {
    padding: 14px 35px;
    float: left;
    background-color: #5C5C5C;
    color: #fff;
    /* margin-left: 20px; */
    text-decoration: none;
    text-transform: uppercase;
    //color: #5C5C5C;
    border: 1px solid #5C5C5C;
    font-size: 10px;
  }
  .blankbtn {
    padding: 14px 12px;
    float: right;
    color: #5C5C5C;
    margin-right: 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: #5C5C5C;
    border: 1px solid #5C5C5C;
    font-size: 10px;
  }
}