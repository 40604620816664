.weddingcard {
  max-width: 630px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  font-family: 'Nunito', sans-serif;
  margin-top: 35px;
}

.weddingcard a {
  color: black;
}

.weddingcard .weddingcard__img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.weddingcard .weddingcard__content {
  padding: 1rem;
  margin-bottom: 2rem;
}

.weddingcard .weddingcard__content h3 {
  text-transform: uppercase;
  font-weight: 600;
  color: #eb934b;
  font-size: 11px;
}

.weddingcard .weddingcard__content > p:nth-of-type(1) {
  font-size: 22px;
  margin: 1rem 0;
}

.weddingcard .weddingcard__content > p:nth-of-type(2) {
  width: 90%;
}
