.mainbodyfrm{
  padding-top: 130px;
  @media screen and (max-width: 768px){
    padding-top: 0px;
  }
  h1{
    font-weight: 100;
  }
}
.widthContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1150px;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 1vmin;
.active{
  border-bottom: 3px solid orange;
}
  ul li {
    text-transform: uppercase;
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
  ul a{
    text-decoration: none;
    list-style-type: none;
  }
 .navlinks{
    display: flex;
    width: 537px;
    justify-content: space-around;
    padding-left: 0px;
  }
}
