.header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999;
  font-family: Nunito, sans-serif !important;
    @media screen and (min-width: 768px) {
      background-color: transparent;
      position: absolute;
      width: 100%;
      color: white;
      border-bottom: 1px solid white;
    }
  }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 768px) {
      text-align: center;
      display: block;
    }
    .header__response {
      display: block;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  
  .header-nav__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    a {
      display: inline-block;
      font-size: 12px;
      text-decoration: none;
      letter-spacing: 1.5px;
      color: #5e5e5e;
      text-transform: uppercase;
      padding: 12px;
      @media screen and (min-width: 768px) {
        padding: 0px;
        //color: white;
      }
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
    background-color: gray;
  }
  .header__nav {
    @media screen and (min-width: 768px) {
      display: block;
    }
  }