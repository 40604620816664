.journalsection{
  text-align: center;
  padding: 0 20px 0 20px;

  a{
    color: black;
  }
  .threeblog{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1366px;;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}