.form__btn {
  font: normal normal normal 12px/16px Nunito, sans-serif;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  background: #5c5c5c;
  width: 100%;
  padding: 9px 8px;
  border: 1px solid orange;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .form__btn {
    font: normal normal normal 20px/15px Nunito, sans-serif;
    letter-spacing: 2.2px;
    padding: 0.8rem 1rem;
  }
}

.loadingimg {
  margin: 0 auto;
  text-align: center;
}

.upcs {
  text-transform: uppercase;
}

.form__container {
  color: #707070;
}

.form__container > h1 {
  text-align: center;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .form__container > h1 {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .form__container {
    font-size: 14px;
  }
}

.form__container .form__heading {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .form__container .form__heading {
    margin: 0 2rem;
    width: auto;
  }
}

.form__container .form__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 1.4rem;
}

.form {
  max-width: 600px;
  margin: 3rem auto;
}

@media screen and (max-width: 768px) {
  .form {
    margin: 2rem 0.5rem;
  }
}

input, textarea {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.form__inputs {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

.w-100 {
  width: 100%;
}

.form__division {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.form__division input {
  width: 0;
  -webkit-box-flex: 0.47;
      -ms-flex: 0.47;
          flex: 0.47;
}

@media screen and (max-width: 768px) {
  .form__address {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .form__address input {
    width: 100%;
  }
}
