.head--1 {
  font: normal normal normal 24px/38px Nunito;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .head--1 {
    font: normal normal normal 40px/64px Nunito;
    letter-spacing: 2px;
  }
}

.head--2 {
  font: normal normal normal 20px/27px Nunito;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .head--2 {
    font: normal normal normal 32px/45px Nunito;
  }
}

.text--primary {
  font: normal normal normal 16px/26px Nunito;
}

@media screen and (min-width: 768px) {
  .text--primary {
    font: normal normal normal 24px/38px Nunito;
  }
}

.text--secondary {
  font: normal normal normal 16px/28px Nunito;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .text--secondary {
    text-align: left;
  }
}

.text--tertiary {
  font: normal normal normal 14px/26px Nunito;
  color: #5c5c5c;
}

.text--accent {
  text-align: center;
  font: normal normal normal 10px/25px Nunito;
  letter-spacing: 1px;
  color: #a19b9b;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .text--accent {
    font: normal normal normal 12px/16px Nunito;
    letter-spacing: 1.2px;
    color: #000000;
    text-align: left;
    margin-left: 1rem;
  }
}

.grid {
  display: -ms-grid;
  display: grid;
}

.grid-cols-2 {
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media screen and (min-width: 769px) {
  .grid-cols-2 {
    -ms-grid-columns: 45fr 55fr;
        grid-template-columns: 45fr 55fr;
  }
}

.btn {
  font: normal normal normal 12px/16px Nunito;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  background: #5c5c5c;
  width: 100%;
  padding: 9px 10px;
  border: 0;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font: normal normal normal 22px/30px Nunito;
    letter-spacing: 2.2px;
    padding: 0.8rem 1rem;
  }
}

.event {
  color: black;
  letter-spacing: 0px;
}

.event__heros {
  margin-top: 120px;
  position: relative;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 95vh;
  background-origin: border-box;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 1px;
}

@media screen and (max-width: 769px) {
  .event__heros {
    margin-top: 0px;
    height: 60vh;
  }
}

.event__heros address {
  font-size: 12px;
  text-transform: capitalize;
  font-style: normal;
  margin: 1.5rem 0 4.5rem;
}

@media screen and (max-width: 768px) {
  .event__heros address {
    font: normal normal normal 14px/29px Nunito;
    margin: 0;
    margin: 1.5rem 0 0rem;
  }
}

.event__hero-container {
  margin-top: 9rem;
  margin-left: 2.5rem;
}

@media screen and (max-width: 768px) {
  .event__hero-container {
    width: 80%;
    margin-top: 3rem !important;
  }
}

.event__hero-head {
  width: 90%;
}

@media screen and (max-width: 769px) {
  .event__hero-head {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}

.event__hero-date {
  font-size: 18px;
  margin: 1.5rem 0;
}

@media screen and (min-width: 769px) {
  .event__hero-date {
    font: normal normal normal 32px/78px Nunito;
    margin: 0;
  }
}

.event__hero-date span {
  font-size: 12px;
}

@media screen and (min-width: 769px) {
  .event__hero-date span {
    font: normal normal normal 16px/78px Nunito;
    margin: 0;
  }
}

.event__hero-time {
  font-size: 14px;
  margin: 1.5rem 0;
}

@media screen and (min-width: 768px) {
  .event__hero-time {
    font: normal normal normal 20px/78px Nunito;
    margin: 0;
  }
}

.btnss {
  margin: 0rem 0 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media screen and (max-width: 768px) {
  .btnss {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.btnss .btn {
  font-size: 10px;
}

.btnss .btn:nth-of-type(1) {
  margin-bottom: 2rem;
  width: 40%;
  background-color: #F18D35;
  margin-right: 3rem;
}

@media screen and (max-width: 768px) {
  .btnss .btn:nth-of-type(1) {
    width: 40%;
    margin-right: 3rem;
  }
}

.btnss .btn:nth-of-type(2) {
  width: 40%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent;
  border: 0.5px solid #ffffff;
}

@media screen and (max-width: 768px) {
  .btnss .btn:nth-of-type(2) {
    width: 40%;
  }
}

.event__hero-location {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 769px) {
  .event__hero-location {
    display: none;
  }
}

.event__hero-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.event__hero-media > div {
  margin-left: 0.7rem;
}

.event__hero-media:hover {
  color: #F18D35;
}

.even__hero-share {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: transparent;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 1rem 0.5rem 0;
  height: 100%;
}

.even__hero-share > div {
  border: 0.5px solid white;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.even__hero-share > div > span {
  margin-left: 5px;
}

.event-scroll {
  height: 65px;
  width: 105px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 2rem 0 4rem;
  font-size: 1.4rem;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.event__hero-locates {
  width: 75%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: (1fr)[1];
      grid-template-rows: repeat(1, 1fr);
  background-color: white;
  color: #918d8d;
  font: normal normal normal 12px/19px Nunito;
  gap: 2rem;
  padding: 2rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.media-mobile {
  display: none;
  margin-top: 3rem;
}

@media screen and (max-width: 769px) {
  .media-mobile {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -ms-grid;
    display: grid;
    width: 100%;
    padding: 2rem 2rem 2rem 7rem;
  }
}

@media screen and (max-width: 426px) {
  .media-mobile {
    padding: 2rem 2rem 2rem 2rem;
  }
}

@media screen and (min-width: 769px) {
  .grid-gap {
    -ms-grid-columns: 40fr 57fr;
        grid-template-columns: 40fr 57fr;
  }
}

.event__media-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 769px) {
  .event__media-container {
    width: 60%;
    margin: auto;
  }
}

@media screen and (max-width: 426px) {
  .event__media-container {
    width: 80%;
    margin: auto;
  }
}

.event__media {
  margin: 4rem 10px;
}

@media (min-width: 790px) and (max-width: 1025px) {
  .event__media {
    margin-left: 2rem;
  }
}

.event__media .event__media-img {
  width: 100%;
  min-height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 426px) {
  .event__media .event__media-img {
    height: 80vh;
  }
}

@media screen and (min-width: 769px) {
  .event__media .event__media-img {
    max-width: 385px;
    height: auto;
  }
}

.event__media .event__media-head {
  width: 15ch;
  text-align: center;
  font: normal normal normal 24px/32px Nunito;
  margin: 2rem auto 0;
  text-transform: uppercase;
}

@media screen and (min-width: 769px) {
  .event__media .event__media-head {
    font: normal normal normal 32px/45px Nunito;
    margin: 2rem 0 0 -1.5rem;
  }
}

.event__media .event__media-year {
  display: block;
  font: normal normal bold 92px/128px Nunito;
  color: #d8d8d86b;
  text-transform: uppercase;
  margin: 2rem 0;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .event__media .event__media-year {
    text-align: left;
  }
}

.event__media .event__media-texts {
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

@media screen and (min-width: 769px) {
  .event__media .event__media-texts {
    -ms-grid-columns: (0.4fr)[2];
        grid-template-columns: repeat(2, 0.4fr);
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }
}

.event__media .event__media-texts .event__media-text {
  margin: 1.5rem auto;
  max-width: 80ch;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .event__media .event__media-texts .event__media-text {
    text-align: left;
    margin: 0;
  }
}

.previous-event__album {
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: (50vh)[6];
      grid-template-rows: repeat(6, 50vh);
  grid-auto-rows: 25vh;
}

@media screen and (min-width: 769px) {
  .previous-event__album {
    -ms-grid-columns: (1fr)[6];
        grid-template-columns: repeat(6, 1fr);
    -ms-grid-rows: (65vh)[2];
        grid-template-rows: repeat(2, 65vh);
    grid-auto-rows: 60vh;
  }
}

.previous__event__images {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.previous__event__images > p {
  display: none;
}

@media screen and (min-width: 769px) {
  .previous__event__images > p {
    display: block;
    width: 100%;
    text-transform: uppercase;
    position: absolute;
    bottom: 0.5%;
    color: #ffffff;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .previous-event__img1 {
    grid-column: auto / span 3;
  }
}

@media screen and (min-width: 769px) {
  .previous-event__img2 {
    grid-column: auto / span 3;
  }
}

.previous-event__testimonal1 {
  margin: 0 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.previous-event__testimonal1 .previous__testimonal1-content::before {
  content: url("../../../images/event/open-quote.png");
  margin-right: 10px;
  margin-top: 1rem;
}

.previous-event__testimonal1 .previous__testimonal1-content {
  text-align: left;
  width: 80%;
  margin: 0 auto;
}

.previous-event__testimonal1 .author {
  font: normal normal normal 24px/33px Nunito;
  letter-spacing: 1.2px;
  margin-top: 0;
  width: 80%;
  margin: 10px auto 0;
}

.previous-event__testimonal1 .status {
  font: normal normal normal 12px/17px Nunito;
  letter-spacing: 0.6px;
  width: 80%;
  margin: 10px auto 0;
}

@media screen and (min-width: 769px) {
  .previous-event__testimonal1 {
    margin: 0;
    grid-column: auto / span 3;
  }
}

@media screen and (min-width: 769px) {
  .previous-event__img3 {
    grid-column: auto / span 3;
  }
}

@media screen and (min-width: 769px) {
  .previous-event__img4 {
    grid-column: auto / span 2;
  }
}

@media screen and (min-width: 769px) {
  .previous-event__img5 {
    grid-column: auto / span 2;
  }
}

.previous-event__testimonal2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.previous-event__testimonal2 .testimonal2__btn {
  width: 90%;
}

@media screen and (min-width: 769px) {
  .previous-event__testimonal2 .testimonal2__btn {
    font-size: 18px;
  }
}

@media screen and (min-width: 769px) {
  .previous-event__testimonal2 {
    grid-column: auto / span 2;
  }
}

.exhibitors {
  margin: 0rem 10px 4rem;
}

@media screen and (min-width: 769px) {
  .exhibitors {
    margin: 6rem 10px;
  }
}

.exhibitors__left {
  max-width: 450px;
  margin: 0 auto;
}

.exhibitors__left .exhibitors__left-head {
  letter-spacing: 4px;
}

@media screen and (min-width: 769px) {
  .exhibitors__left .exhibitors__left-head {
    text-align: left;
  }
}

.exhibitors__right {
  margin-top: 2rem;
  margin-bottom: 2rem;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  row-gap: 3rem;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (min-width: 769px) {
  .exhibitors__right {
    -ms-grid-columns: (1fr)[4];
        grid-template-columns: repeat(4, 1fr);
  }
}

.exhibitor__btn {
  margin: 2rem 0 1rem;
}

.exhibitor__move {
  display: none;
}

@media screen and (min-width: 769px) {
  .exhibitor__move {
    display: inline-block;
  }
}

@media screen and (min-width: 769px) {
  .exhibitor__down {
    display: none;
  }
}

.locationContainer {
  margin: 0 auto;
  text-align: center;
}

.locationContainer h1 {
  text-align: center;
  margin-bottom: 60px;
  font-size: 20px;
  letter-spacing: 6.4px;
}

.locationContainer h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 19px;
  letter-spacing: 0px;
}

.locationContainer p {
  text-align: center;
  margin-bottom: 50px;
  color: orange;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 46px;
}

.event-testimonal {
  margin-bottom: 5rem;
}

.event-testimonal .legend {
  margin: 4rem 0;
}
