.workbody {
  margin-top: 150px;
  text-align: center;
  @media screen and (max-width: 768px){
    margin-top: 0;
  }

  h3 {
    font: normal normal bold 28px/38px Nunito Sans;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 1;
  }

   p {
    width: 600px;
    margin: 0 auto;
     font-family: Nunito, sans-serif !important;
     font-size: 12px;
     @media screen and (max-width: 768px){
       width: 100%;
       margin: 0 auto;
     }
  }
  .wroktitle{
    font: normal normal 600 18px/24px Nunito Sans;
    letter-spacing: 0px;
    color: #2A2A2A;
    opacity: 1;
  }
  .wroklist{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px gray solid;
    width: 600px;
    margin: 0 auto;
    padding: 10px;
    a{
      color: black;
      text-decoration: none;
    }
    @media screen and (max-width: 768px){
      display: flex;
      justify-content: space-between;
      border-bottom: 1px gray solid;
      width: 100%;
      margin: 0 auto;
      padding: 10px;
      flex-direction: column;
      text-align: left;
    }
  }
  .wroklistcolo {
    a {
      color: #FF840D;
      font: normal normal 600 12px/16px Nunito Sans;
      letter-spacing: 0px;
      color: #FF840D;
      opacity: 1;
    }
  }
}