.topmrgin {
  padding-top: 140px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .topmrgin {
    padding-top: 0;
  }
}

.hmdis {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
