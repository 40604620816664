.rcards{
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 768px) {
    overflow: auto;
    white-space: nowrap;
    margin: 14px;
    justify-content: start;
  }

}

