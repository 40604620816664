.mainbody {
  padding: 160px 60px 60px 60px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.mainbody a {
  color: black;
}

@media screen and (max-width: 768px) {
  .mainbody {
    width: 90%;
    padding: 12px;
  }
}
