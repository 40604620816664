.mixblogtop {
  padding-top: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .mixblogtop {
    padding-top: 0;
  }
}
