.img__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[6];
      grid-template-columns: repeat(6, 1fr);
  -ms-grid-rows: (22vh)[4];
      grid-template-rows: repeat(4, 22vh);
}

@media screen and (max-width: 768px) {
  .img__grid {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: (35vh)[3];
        grid-template-rows: repeat(3, 35vh);
  }
}

.img__grid h1, .img__grid h2, .img__grid h3 {
  text-transform: uppercase;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .img__grid h1, .img__grid h3 {
    font-size: 12px;
    margin-bottom: 1rem;
  }
}

.img__grid .gridimg-1 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/span 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  grid-row: 1/span 4;
  position: relative;
  padding-left: 5vmin;
}

.img__grid .gridimg-1 p {
  margin: 2rem 0 3rem;
  max-width: 70ch;
}

@media screen and (max-width: 768px) {
  .img__grid .gridimg-1 p {
    font-size: 8px;
    margin: 0rem 0 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .img__grid .gridimg-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/span 2;
    grid-row: auto/span 1;
  }
}

.img__grid .gridimg-2 {
  -ms-grid-column: 5;
  -ms-grid-column-span: 2;
  grid-column: 5/span 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/span 2;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .img__grid .gridimg-2 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/span 2;
    grid-row: auto/span 1;
  }
}

.img__grid .gridimg-3 {
  -ms-grid-column: 5;
  -ms-grid-column-span: 1;
  grid-column: 5/span 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / span 2;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .img__grid .gridimg-3 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/span 1;
    grid-row: auto/span 1;
  }
}

.img__grid .gridimg-4 {
  -ms-grid-column: 6;
  -ms-grid-column-span: 1;
  grid-column: 6/ span 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / span 2;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .img__grid .gridimg-4 {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/span 1;
    grid-row: auto/span 1;
  }
}

.img__grid .gridimg {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: white;
}

.img__grid .expand__image {
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .img__grid .expand__image {
    font-size: 11px;
  }
}

.img-grid__play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
  width: 30px !important;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .img-grid__play {
    width: 20px !important;
    height: 20px !important;
  }
}

.img-grid__playimg {
  width: 100%;
  height: 100% !important;
}
