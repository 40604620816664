.bgmodelbridestory {
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: none;
  z-index: 100;
}

.bgmodelbridestory .modelconten {
  width: 449px;
  height: 440px;
  background-color: #fff;
  text-align: center;
  position: relative;
}

.bgmodelbridestory .modelcontentimage {
  height: 102px;
  width: 102px;
  border-radius: 50%;
  margin-top: 23px;
}

.bgmodelbridestory .modelcontentname {
  margin-top: 25px;
  font-size: 18px;
  color: #5C5C5C;
  text-align: center;
  margin-bottom: 0;
}

.bgmodelbridestory .modelcontentbridtobe {
  margin-top: 30px;
  font-size: 12px;
  color: #5C5C5C;
  text-transform: uppercase;
  margin-bottom: 0;
}

.bgmodelbridestory .modelcontentpara {
  font-size: 14px;
  color: #5C5C5C;
  text-align: center;
  width: 80%;
  margin: 35px auto 0 auto;
}

.bgmodelbridestory .buttons {
  width: 80%;
  margin: 55px auto 0 auto;
}

.bgmodelbridestory .fillbtn {
  padding: 14px 35px;
  float: left;
  background-color: #5C5C5C;
  color: #fff;
  /* margin-left: 20px; */
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #5C5C5C;
  font-size: 10px;
}

.bgmodelbridestory .blankbtn {
  padding: 14px 12px;
  float: right;
  color: #5C5C5C;
  margin-right: 20px;
  text-decoration: none;
  text-transform: uppercase;
  color: #5C5C5C;
  border: 1px solid #5C5C5C;
  font-size: 10px;
}
