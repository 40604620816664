.Gridcontainer {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

@media screen and (max-width: 768px){

  overflow: auto;
  justify-content: start;
}
  div {
    padding: 12px;
    img{
      vertical-align: middle;
    }
    @media screen and (max-width: 768px){
      display: inline-block;
      color: white;
      text-align: center;
      padding: 14px;
    }
  }
}
.bridecard{
  padding: 12px;
}
.sccscr{
  padding-top: 130px;
  @media screen and (max-width: 768px){
padding-top: 0;
  }
  h1{
    margin-top: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
    letter-spacing: 2.2px;
  }
  p{
    margin-bottom: 30px;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #A8A6A6;
    margin-top: 20px;
  }
}