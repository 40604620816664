.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
  font-family: Nunito, sans-serif !important;
}

@media screen and (min-width: 768px) {
  .header {
    background-color: transparent;
    position: absolute;
    width: 100%;
    color: white;
    border-bottom: 1px solid white;
  }
}

.header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (min-width: 768px) {
  .header__container {
    text-align: center;
    display: block;
  }
}

.header__container .header__response {
  display: block;
}

@media screen and (min-width: 768px) {
  .header__container .header__response {
    display: none;
  }
}

.header-nav__list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
}

.header-nav__list a {
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 1.5px;
  color: #5e5e5e;
  text-transform: uppercase;
  padding: 12px;
}

@media screen and (min-width: 768px) {
  .header-nav__list a {
    padding: 0px;
  }
}

@media screen and (min-width: 768px) {
  .header-nav__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
  background-color: gray;
}

@media screen and (min-width: 768px) {
  .header__nav {
    display: block;
  }
}
