.grid {
  display: -ms-grid;
  display: grid;
}

.grid-cols-2 {
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .grid-cols-2 {
    -ms-grid-columns: 45fr 55fr;
        grid-template-columns: 45fr 55fr;
  }
}

.footer {
  padding: 4rem 2rem 1rem;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 4rem 4rem 1rem 4rem;
    -webkit-box-shadow: 0px 0px 6px #000000c2;
            box-shadow: 0px 0px 6px #000000c2;
  }
}

.footer__container {
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .footer__container {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.footer__head {
  font: normal normal normal 14px/29px Nunito, sans-serif;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.footer__left-logo {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .footer__left-logo {
    margin: 0;
  }
}

.footer__left-text {
  text-align: left;
  max-width: 33ch;
  letter-spacing: 0px;
  color: #a19b9b;
  opacity: 1;
  font-size: 1.1rem;
}

@media screen and (min-width: 768px) {
  .footer__left-text {
    text-align: left;
    max-width: 33ch;
    letter-spacing: 0px;
    color: #a19b9b;
    opacity: 1;
    font-size: 1.1rem;
  }
}

.footer__list {
  list-style: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.footer__list a {
  color: black;
  text-decoration: none;
  font-size: 1.1rem;
}

.footer__left-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 3rem 0;
}

@media screen and (min-width: 768px) {
  .footer__left-list {
    width: 50%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.footer__right-list {
  font: normal normal normal 12px/29px Nunito, sans-serif;
  text-shadow: 0px 3px 6px #00000029;
}

.footer__left-input {
  width: 100%;
  border: 1px solid #c7c7c7;
  margin-bottom: 1rem;
  padding: 1rem 1.1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.btn {
  font: normal normal normal 12px/16px Nunito, sans-serif;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  background: #5c5c5c;
  width: 100%;
  padding: 9px 10px;
  border: 0;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font: normal normal normal 22px/30px Nunito, sans-serif;
    letter-spacing: 2.2px;
    padding: 0.8rem 1rem;
  }
}

.footer__right-btn {
  width: 100%;
  font: normal normal normal 12px/17px Nunito, sans-serif;
  letter-spacing: 1.2px;
}

.footer__right-newsletter {
  letter-spacing: 2.8px;
}

.footer__right-text {
  font: normal normal normal 12px/23px Nunito, sans-serif;
  margin-top: 0;
}

.footer__copyright {
  display: none;
}

@media screen and (min-width: 768px) {
  .footer__copyright {
    display: block;
  }
}

.footer__copyright > p {
  text-align: center;
  text-transform: uppercase;
  font: normal normal normal 12px/24px Nunito, sans-serif;
  letter-spacing: 0px;
  color: #a19b9b;
  opacity: 1;
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .exhibitor__down {
    display: none;
  }
}

.exhibitor__move {
  display: none;
  font-size: 1.1rem;
}

@media screen and (min-width: 768px) {
  .exhibitor__move {
    display: inline-block;
  }
}

.loadingimag img {
  width: 130px;
}
