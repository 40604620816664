.slick-slider {
  ul li button {
    &::before {
      color: black;
      font-size: 12px;
      opacity: 0.7;
      @media screen and (max-width: 768px) {
        font-size: 8px;
    }
    }
  }
  li.slick-active button::before {
    color: white;
  }

  .slider__img {
    object-fit: cover;
    position: absolute;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-position: center;
  }
}
.slick-dots {
  bottom: 4%;
  right: 4%;
  text-align: right;
  @media screen and (max-width: 768px) {
      text-align: left;
      left: 4%;
    width: 90%;
  }
}
.slider-container {
  text-transform: uppercase;
  font-family: Nunito, sans-serif ;
  color: white;
  position: relative;
  width: 100%;
  height: 104vh;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000ED 100%) 0% 0% no-repeat padding-box;
  @media screen and (max-width:768px) {
    height: 90vh;
  }
    .slider-content{
        margin-left: 5rem;
        margin-bottom: 5rem;
        &>p{
            margin: 3rem 0;
        }
        &>h2{
            margin: 3rem 0;
            font-weight: 500;
        }
        @media screen and (max-width: 768px) {
            margin-left: 1rem;
            margin-bottom: 5rem;
        }
    }
  .slider-btn {
    font: normal normal normal 12px/16px Nunito;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background: transparent;
    // width: 100%;
    padding: 9px 10px;
    border: 1px solid white;
    cursor: pointer;
    a{
        opacity: 0.8;
        color: #ffffff;
        text-decoration: none;
    }
    @media screen and (min-width: 768px) {
      width: fit-content;
      font: normal normal normal 20px/25px Nunito;
     // letter-spacing: 2.2px;
      padding: 0.4rem 0.2rem;
    }
  }
}
.slick-next{
    display: none !important;
}
.slick-prev{
    display: none !important;

}