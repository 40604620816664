.journalsection {
  text-align: center;
  padding: 0 20px 0 20px;
}

.journalsection a {
  color: black;
}

.journalsection .threeblog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .journalsection .threeblog {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
