a{
  text-decoration: none;
}
.blogcard {
  width: 350px;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  img{
      width: 90%
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto;
}
}
