.event_container {
  margin: 6% 2%;
  margin-bottom: 5%;
  /* display: flex; */
  justify-content: space-between;
  text-align: left;
  border: 5px solid #f5f5f5;
  padding-bottom: 24px;
  width: 268px;
  height: 350px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    margin: 10 auto;
    .title {
      width: 50%;
      font-size: 20px;
      line-height: 3rem;
      text-align: center;
      margin: 20px auto 10px;
    }
    span {
      color: #2bbeb9;
      .firstword {
        color: #2bbeb9;
      }
    }
  }

  .title {
    color: #c7c7c7;
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 37px;
    text-transform: uppercase;
    span {
      color: #2bbeb9;
      .firstword {
        color: #2bbeb9;
      }
    }
  }
  .eventPlace {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
  }
  .evetnDate {
    font-size: 17px;
    letter-spacing: 0px;
    line-height: 60px;
    font-weight: 300;
  }
  .eventRegis {
    background-color: #5c5c5c !important;
    color: white !important;
    text-decoration: none;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    padding: 0.6rem 1rem;
    margin: 0.5rem 0;
    text-align: center;
    outline: none;
    border: none;
  }
  .knoemOre {
    background-color: white;
    color: #5c5c5c;
    text-decoration: none;
    font-size: 10px;
    // padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
  }
  a:last-child {
    height: 40px;
    background-color: white;
    border: 0.5px solid #000000;
    width: 70%;
    margin: 0 auto;
  }
  .link {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    button {
      width: 70%;
    }
  }
}
